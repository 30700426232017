:root {
  --color-primary: #eb2f64;
  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-0: rgb(21, 21, 21);
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --shadow: 0 0.8rem 0 rgba(4, 1, 1, 0.15);

  --line: 1px solid var(--color-grey-light-2);

  --red: hsl(0, 100%, 74%);
  --green: hsl(154, 59%, 51%);
  --blue: hsl(248, 32%, 49%);
  --top-box: #5d54a3;
}

$bp-medium: 41.125em; // 658px
$bp-small-1: 37.5em; // 600px
$bp-small-2: 26.875em; // 430px
$bp-small-3: 23.4375em; // 375px

* {
  margin: 0;
  padding: 0;
  // outline: 1px solid white;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  //   font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

.login-main {
  font-family: "Poppins", sans-serif;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

  //   font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6;

  // background-repeat: no-repeat;
  // background-image: url("../assets/images/lurer-background.jpg");
  // background: gray;

  // background-size: cover;

  min-height: 100vh;
}

.login-wrapper {
  background: linear-gradient(
    135deg,
    rgba(64, 64, 64, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
}

.main-container {
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
  gap: 50px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media only screen and (max-width: $bp-small-2) {
    padding: 50px 10px 0 10px;
  }

  @media only screen and (max-width: $bp-small-3) {
    padding: 10rem 2.4rem 0 2.4rem;
  }
}
