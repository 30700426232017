.stories.carousel .story {
  width: 110px;
  max-width: 110px;
  margin: 0 5.5px;
}

.stories.carousel .story > .item-link > .item-preview {
  width: 80px;
  max-height: 80px;
  height: 80px;
  margin: 0 15px;
}

.stories.carousel .story > .item-link > .info .name {
  font-size: 13px;
  line-height: 13px;
}
