.stories.snapgram .story > .item-link {
  text-decoration: none;
  color: #333;
}

.stories.snapgram .story > .item-link > .item-preview {
  border-radius: 50%;
  padding: 2px;
  background: radial-gradient(
    ellipse at 70% 70%,
    #ee583f 8%,
    #d92d77 42%,
    #bd3381 58%
  );
}

.stories.snapgram .story > .item-link > .item-preview img {
  border-radius: 50%;
  border: 3px solid #fff;
}

.stories.snapgram .story.seen {
  opacity: 0.75;
}

.stories.snapgram .story.seen > a > .item-preview {
  background: #999;
}

.stories.snapgram .story.seen > a {
  color: #999 !important;
}
