@use "base";

.left-panel {
  flex: 1;
  color: #fff;

  @media only screen and (max-width: 1024px) {
    margin: 0;
    text-align: center;
  }

  @media only screen and (max-width: base.$bp-small-2) {
    width: auto;
    margin: 0;
    text-align: center;
  }

  &__title {
    font-size: 60px;
    font-weight: 700;
    line-height: 58px;
    margin-top: 0.7rem;

    @media only screen and (max-width: base.$bp-small-2) {
      font-size: 2.4rem;
      line-height: 3rem;
      font-weight: 600;
      margin-top: -0.8rem;
    }
  }

  &__description {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 2.7rem;
    word-spacing: 0rem;
    text-align: left;
    opacity: 0.9;

    @media only screen and (max-width: 1024px) {
      text-align: center;
    }

    @media only screen and (max-width: base.$bp-small-2) {
      margin: 2.3rem 0 0 0;
      text-align: center;
    }
  }
}

.right-panel {
  flex: 1;
  width: 100%;
  //   @media only screen and (max-width: base.$bp-small-2) {
  //     width: 100%;
  //   }

  &__form {
    display: flex;
    flex-direction: column;

    background: white;
    margin-top: 2.4rem;
    border-radius: 1rem;

    padding: 50px 20px;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media only screen and (max-width: base.$bp-small-2) {
      padding: 20px;
    }

    @media only screen and (max-width: base.$bp-small-3) {
      padding: 2.4rem;
    }

    &__section {
      position: relative;
      height: 70px;
      margin-bottom: 20px;

      @media only screen and (max-width: base.$bp-small-2) {
        margin-bottom: 1.6rem;
      }

      &__input {
        width: 100%;
        // height: 5.6rem;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #000;

        padding: 0 20px;
        // margin-bottom: 2rem;
        outline: none;
        border-radius: 0.5rem;
        border: 1px solid var(--color-grey-light-3);

        &:focus {
          border: 1px solid var(--blue);
          caret-color: var(--blue);
        }
        &:focus
          + .right-panel__form__section__label
          .right-panel__form__section__label__content,
        &:valid
          + .right-panel__form__section__label
          .right-panel__form__section__label__content {
          visibility: hidden;
        }

        &::placeholder {
          color: #262626;
        }
      }

      &__input.error {
        border: 2px solid var(--red);
        color: var(--red);
      }

      &__label {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
      }

      &__label {
        pointer-events: none;

        &__content {
          position: absolute;
          top: 1.7rem;
          left: 3.3rem;
          font-size: 20px;
          font-weight: 600;
          color: var(--color-grey-dark-3);

          @media only screen and (max-width: base.$bp-small-2) {
            left: 1.9rem;
          }
        }
      }

      &__icon-error {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2.7rem;
      }
    }

    &__btn {
      cursor: pointer;
      background: -moz-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.9) 22%,
        rgba(4, 19, 37, 0.88) 26%,
        rgba(22, 115, 222, 0.92) 46%,
        rgba(22, 115, 222, 0.95) 62%,
        rgba(22, 115, 222, 0.98) 76%,
        rgba(13, 67, 129, 1) 86%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.9) 22%,
        rgba(4, 19, 37, 0.88) 26%,
        rgba(22, 115, 222, 0.92) 46%,
        rgba(22, 115, 222, 0.95) 62%,
        rgba(22, 115, 222, 0.98) 76%,
        rgba(13, 67, 129, 1) 86%,
        rgba(0, 0, 0, 1) 100%
      );
      background: linear-gradient(
        135deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.9) 22%,
        rgba(4, 19, 37, 0.88) 26%,
        rgba(22, 115, 222, 0.92) 46%,
        rgba(22, 115, 222, 0.95) 62%,
        rgba(22, 115, 222, 0.98) 76%,
        rgba(13, 67, 129, 1) 86%,
        rgba(0, 0, 0, 1) 100%
      );
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      height: 5.6rem;
      border: none;
      border-radius: 0.5rem;
      padding-top: 0.2rem;
      letter-spacing: 0.1rem;
    }

    .message-error {
      text-align: right;
      font-size: 1.1rem;
      font-weight: 500;
      font-style: italic;
      color: var(--red);

      margin-top: -1.5rem;
      padding-bottom: 1.9rem;
    }
  }
}
